import Spinner from 'components/spinner';
import { rem, size } from 'polished';
import PropTypes from 'prop-types';
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';
import styled, { css } from 'styled-components';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  block?: boolean;
  variant?: 'default' | 'dark' | 'secondary' | 'transparent';
  size?: 'large' | 'small';
  type?: 'submit' | 'reset' | 'button';
  as?: 'a';
  href?: string;
  loading?: boolean;
}

export const StyledButton = styled.button.attrs((props: Props) => ({
  type: props.as === 'a' ? undefined : props.type ?? 'button',
  variant: props.variant ? props.variant : 'default',
}))<Props>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ block }) => (block ? '100%' : undefined)};
  min-width: ${rem(120)};
  height: ${({ size }) => (size === 'large' ? '55px' : '48px')};
  color: ${({ theme, variant }) => theme.components.button.text[variant]};
  font-weight: bold;
  font-size: ${({ size }) => (size === 'large' ? '20px' : '16px')};
  line-height: 1.15;
  text-decoration: none;
  hyphens: manual;
  background-color: ${({ theme, variant }) =>
    theme.components.button.background[variant]};
  border: 1px solid
    ${({ variant, theme }) => theme.components.button.border[variant]};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  cursor: pointer;
  transition:
    box-shadow 150ms ease-in-out,
    border-color 150ms ease-in-out,
    background-color 150ms ease-in-out,
    color 150ms ease-in-out;

  ${({ theme }) => theme.mediaQueries.small} {
    min-width: ${rem(160)};
  }

  ${({ variant }) => css`
    padding: ${variant === 'transparent' ? `0 ${rem(12)}` : `0 ${rem(45)}`};
  `};

  & > svg,
  ${Spinner} {
    ${size(rem(20))};

    position: absolute;
    left: ${rem(20)};
    display: block;
    margin: 0;
  }

  &.disabled,
  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &.focus,
  &:focus {
    ${({ variant, theme }) => css`
      border-color: ${theme.colors.palette.white};
      outline: 0;
      box-shadow: ${variant === 'transparent'
        ? 'none'
        : `0 0 0 2px ${theme.colors.palette.redDark}, 0 0 9px ${theme.colors.palette.redDark}, 0 0 8px ${theme.colors.palette.redDark}`};
    `}
  }

  &.hover:not(:disabled),
  &:hover:not(:disabled) {
    ${({ variant, theme }) => css`
      color: ${variant === 'transparent'
        ? theme.colors.palette.lightBlue
        : theme.colors.palette.white};
      background-color: ${variant === 'transparent'
        ? 'transparent'
        : theme.colors.palette.black};
      border-color: ${variant === 'transparent'
        ? 'transparent'
        : theme.colors.palette.black};
    `}
  }
`;

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ children, disabled, loading, ...props }, ref) => {
    return (
      <StyledButton
        {...(props as any)}
        disabled={loading || disabled}
        ref={ref}
      >
        {loading && <Spinner size={20} />}
        {children}
      </StyledButton>
    );
  }
);

Button.defaultProps = {
  block: false,
  size: 'small',
  variant: 'default',
};

Button.propTypes = {
  block: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'dark', 'secondary', 'transparent']),
  size: PropTypes.oneOf(['large', 'small']),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  as: PropTypes.oneOf(['a']),
};

export default Button;
