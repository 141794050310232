import { ReactComponent as Cross } from 'assets/icons/icon-cross.svg';
import BackLink from 'components/back-link';
import { FC, useCallback, useMemo } from 'react';
import { useStore } from 'store';
import {
  CloseButton,
  Header,
  SaveButton,
  SaveButtonWrapper,
} from './plan-controls.styles';

export type PlanControlOptions = {
  close: boolean;
  save: boolean;
};

interface Props {
  onDismiss?: () => void;
  onSave?: () => void;
  backLink?: string;
  options?: true | PlanControlOptions;
}

const PlanControls: FC<Props> = ({ onDismiss, onSave, options, backLink }) => {
  const [set, navigation] = useStore((state) => [state.set, state.navigation]);
  const href = useMemo(() => backLink, [backLink]);
  const showClose = useMemo(
    () => !backLink && (options === true || options?.close === true),
    [backLink, options]
  );
  const showSave = useMemo(
    () => !backLink && (options === true || options?.save === true),
    [backLink, options]
  );

  const resetBacklink = useCallback(() => {
    if (navigation.backLink) {
      set(({ navigation }) => {
        navigation.backLink = '';
      }, 'resetBacklink');
    }
  }, [navigation.backLink, set]);

  return (
    <>
      <Header>
        {href && (
          <BackLink
            href={href}
            as={href?.split('?')[0]}
            onClick={resetBacklink}
          >
            Continue Editing
          </BackLink>
        )}
        {showClose && (
          <CloseButton onClick={onDismiss} aria-label='Close plan'>
            <Cross aria-hidden role='presentation' />
          </CloseButton>
        )}
      </Header>

      {showSave && (
        <SaveButtonWrapper>
          <SaveButton onClick={onSave}>Save &amp; exit</SaveButton>
        </SaveButtonWrapper>
      )}
    </>
  );
};

export default PlanControls;
