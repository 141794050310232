import Link, { LinkProps } from 'next/link';
import React, { FC } from 'react';
import { BackLinkStyled } from './back-link.styles';

interface Props extends React.PropsWithChildren<LinkProps> {
  onClick?: () => void;
}

const BackLink: FC<Props> = ({ onClick, children, ...linkProps }) => {
  return (
    <Link {...linkProps} passHref>
      <BackLinkStyled onClick={onClick}>{children}</BackLinkStyled>
    </Link>
  );
};

export default BackLink;
