import { PlanData } from '../client';
import { PLAN_VERSION } from './constants';
import { migrate as migrateV1toV2 } from './v1tov2';

export interface MigrationSuccessPayload {
  type: 'migrated';
  plan: PlanData;
  stepsToMigrate: number;
}
export interface MigrationNotRequiredPayload {
  type: 'not-required';
  version: number;
}
export interface MigrationFailedPayload {
  type: 'migration-failed';
  plan: PlanData;
  toVersion: number;
  failedStep: number;
}

export interface PlanDataMigrationError {
  failedVersion: number;
}

export function migratePlan(
  plan: PlanData,
  steps: number = 0
):
  | MigrationSuccessPayload
  | MigrationNotRequiredPayload
  | MigrationFailedPayload {
  switch (plan.version) {
    case 1: {
      const leaveEarly = migrateV1toV2(plan);
      return migratePlan(leaveEarly, steps + 1);
    }
    // When we get a version 3
    /**
     * case 2:
     * return migratePlan({...plan, plan: {...plan.plan, version: 3}}, appPlanVersion, steps + 1)
     */
    case PLAN_VERSION:
      return steps === 0
        ? { type: 'not-required', version: plan.version }
        : { type: 'migrated', plan, stepsToMigrate: steps };
    default:
      return {
        type: 'migration-failed',
        plan: { ...plan, completed: false, completionAcknowledged: false },
        toVersion: PLAN_VERSION,
        failedStep: steps,
      };
  }
}
