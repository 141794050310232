import { logger } from '@hatchd/utils';
import { ReactComponent as AlertIcon } from 'assets/icons/icon-alert.svg';
import {
  AlertDialogActionsWrapper,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogLabel,
  AlertDialogOverlay,
} from 'components/alert-dialog';
import Button from 'components/button';
import Footer from 'components/footer';
import Header from 'components/header';
import PlanControls, { PlanControlOptions } from 'components/plan-controls';
import { derivePlanTitle } from 'components/plan-summary/utils';
import PreviewBanner from 'components/preview-banner';
import UpgradeMessageDialog from 'components/upgrade-message-dialog';
import useAuth from 'hooks/use-auth';
import { usePlanAPI } from 'hooks/use-plan';
import { useSiteSettings } from 'hooks/use-siteSettings';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { logCreatePlan, logDismissPlan } from 'services/analytics';
import { useStore } from 'store';
import { getPlanLink } from 'utils/helpers';
import { getPlanStepId } from 'utils/plan-helpers';
import APP_ROUTES from 'utils/routes';
import { ContentWrapper, GlobalStyles, Main, SkipNav } from './layout.styled';

// Dynamically import components not needed right away
const SingleUseDialog = dynamic(() => import('components/single-use-dialog'));

export type Props = {
  preview?: boolean;
  mainBackground?: string | string[];
  dimBackground?: boolean;
  showPlanControls?: boolean | PlanControlOptions;
  showEditControls?: boolean;
  afterMainContent?: ReactElement;
};

const Layout: FC<Props> = ({
  preview,
  children,
  mainBackground,
  showPlanControls = false,
  showEditControls = false,
  dimBackground = false,
  afterMainContent,
}) => {
  const { siteSettings } = useSiteSettings();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { loading, isAuthenticated, isAnonymous } = useAuth();
  const [getPlanId, setPlanId] = useStore((state) => [
    state.getPlanId,
    state.setPlanId,
  ]);
  const getPlanDraftDirtyFlag = useStore(
    (state) => state.getPlanDraftDirtyFlag
  );
  const getPlanDraft = useStore((state) => state.getPlanDraft);
  const navigation = useStore((state) => state.navigation);
  const clearStore = useStore((state) => state.clearStore);
  const [planType, planStep] = useStore((state) => [
    state.plan.draft.type,
    state.progress.planStep,
  ]);
  const { usePlans, useCreatePlan, useUpsertPlan } = usePlanAPI();
  const createRemotePlan = useCreatePlan();
  const updateRemotePlan = useUpsertPlan();
  const { data: remotePlanData } = usePlans();

  const [showClosePlannerModal, setShowClosePlannerModal] = useState(false);
  /** Ref to the least destructive action (the dismiss action) */
  const cancelActionRef = useRef<HTMLButtonElement | null>(null);

  /** User decides to continue working on the plan. */
  const handleContinueEditing = () => setShowClosePlannerModal(false);

  /** User decides to stop working on the plan. Plan & survey resets. */
  const handleLeaveWithoutSaving = useCallback(() => {
    // Need to clear the store _after_ navigation, otherwise
    // it will contain the data of the last response the user was on
    // due to the `unMountEffect` getting triggered.
    router.push(APP_ROUTES.home).then(() => {
      clearStore();
    });
  }, [clearStore, router]);

  /** Determines the routes based on the plan type the user is on. */
  const planRoute = useMemo(() => getPlanLink(planType), [planType]);

  /** Backlink used for PlanControls */
  const backLink = useMemo(() => {
    const storedBacklink = navigation.backLink;
    if (storedBacklink) {
      return storedBacklink;
    }
    if (planRoute) {
      return `${planRoute.plan}?step=${planStep}`;
    } else {
      // eslint-disable-next-line no-script-url -- Unlikely fallback.
      return 'javascript:history.back()';
    }
  }, [navigation, planRoute, planStep]);

  const showDefaultNav = !showPlanControls && !showEditControls;

  const createPlan = useCallback(async () => {
    const currentDraft = getPlanDraft();
    const newPlanName = derivePlanTitle(
      currentDraft,
      remotePlanData?.data || []
    );
    try {
      const res = await createRemotePlan.mutateAsync({
        plan: { ...currentDraft, name: newPlanName },
      });
      const planId = res!.data.planDataWithMetadata.id;
      setPlanId(planId);
      // Log if this is the first save of the plan
      logCreatePlan(currentDraft.type, currentDraft);
    } catch (error) {
      logger.error('Error creating plan', error);
    }
  }, [createRemotePlan, getPlanDraft, remotePlanData?.data, setPlanId]);

  const savePlan = useCallback(async () => {
    const currentDraft = getPlanDraft();
    const currentPlanId = getPlanId();
    const isPlanDirty = getPlanDraftDirtyFlag();

    if (!isPlanDirty) {
      logger.log('Layout: Clean, nothing to save');
      return;
    }

    logger.info('Layout: Dirty, saving!');

    try {
      await updateRemotePlan.mutateAsync({
        id: currentPlanId,
        plan: currentDraft,
      });
    } catch (error) {
      logger.error('Error updating plan', error);
    }
  }, [getPlanDraft, getPlanDraftDirtyFlag, getPlanId, updateRemotePlan]);

  /** User clicks 'Save & Exit' */
  const handleSaveClick = useCallback(async () => {
    // All users (anon and registered):
    //   - If there is a user session and no plan id, create a plan
    // Registered users:
    //   - Save the plan
    //   - Go to plan summary
    // Anon users:
    //   - Save the plan
    //   - Go to sign up page

    //? NOTE:
    //? To ensure we have the latest store state, we need to create/save the plan
    //? after the page transition (`router.push`).

    // REGISTERED USERS
    // If they have a plan, auto-save it.
    // Otherwise, create it at that point.
    if (planRoute && !loading && isAuthenticated && !isAnonymous) {
      router.push(planRoute.summary).then(() => {
        // get the plan id after redirect to ensure the correct plan is show in summary
        const currentPlanId = getPlanId();
        if (currentPlanId) {
          savePlan();
        }
      });
    }

    // ANONYMOUS USERS
    if (!loading && isAuthenticated && isAnonymous) {
      const controls = `widthEditControls=true`;
      router
        .push(
          `${APP_ROUTES.users.signUp}/?${controls}`,
          APP_ROUTES.users.signUp
        )
        .then(async () => {
          const currentPlanId = getPlanId();
          if (currentPlanId) {
            await savePlan();
          } else {
            await createPlan();
          }
        });
    }
  }, [
    createPlan,
    getPlanId,
    isAnonymous,
    isAuthenticated,
    loading,
    planRoute,
    router,
    savePlan,
  ]);

  /** User clicks 'X', trying to close the planner. */
  const handleDismissClick = useCallback(() => {
    const planDraft = getPlanDraft();
    logDismissPlan(
      planStep,
      getPlanStepId(planStep, planDraft.type),
      planDraft
    );

    // REGISTERED USERS
    // - With current plan: move to summary and save.
    // - Without current plan: move to summary.
    if (!loading && isAuthenticated && !isAnonymous) {
      if (planRoute) {
        router.push(planRoute.summary).then(async () => {
          const currentPlanId = getPlanId();
          if (currentPlanId) {
            await savePlan();
          }
        });
      }
    }

    // ANONYMOUS USERS
    // Anon users: Show modal with actions.
    // Exception: step 9 goes to summary!
    if (!loading && isAuthenticated && isAnonymous) {
      if (planRoute && planStep === 9) {
        router.push(planRoute.summary);
      } else {
        setShowClosePlannerModal(true);
      }
    }
  }, [
    getPlanDraft,
    getPlanId,
    isAnonymous,
    isAuthenticated,
    loading,
    planRoute,
    planStep,
    router,
    savePlan,
  ]);

  useEffect(() => {
    // Reset all plan & survey data if the user becomes
    // unauthenticaed for whatever reason.
    if (!loading && !isAuthenticated) {
      logger.info('Not authenticated. Clearing store');
      queryClient.clear();
      clearStore();
    }
  }, [clearStore, isAuthenticated, loading, queryClient]);

  return (
    <>
      <GlobalStyles />
      <SkipNav>Skip to content</SkipNav>
      <PreviewBanner preview={preview} />
      <ContentWrapper>
        {showDefaultNav && <Header nav={siteSettings.nav.header} />}
        <Main
          id='maincontent'
          background={mainBackground}
          dimBackground={dimBackground}
        >
          {children}
        </Main>
        {afterMainContent}
        {showDefaultNav && <Footer nav={siteSettings.nav.footer} />}
        {showPlanControls && (
          <PlanControls
            onDismiss={handleDismissClick}
            onSave={handleSaveClick}
            options={showPlanControls}
          />
        )}
        {showEditControls && <PlanControls backLink={backLink} />}

        {showClosePlannerModal && (
          <AlertDialogOverlay leastDestructiveRef={cancelActionRef}>
            <AlertDialogContent>
              <AlertDialogLabel>Your plan will be lost</AlertDialogLabel>
              <AlertDialogDescription>
                You're about to close your bushfire plan. Without saving, all
                your changes will be lost.
              </AlertDialogDescription>
              <AlertDialogActionsWrapper>
                <Button onClick={handleContinueEditing} ref={cancelActionRef}>
                  Continue editing
                </Button>
                <Button onClick={handleSaveClick}>Save</Button>
                <Button variant='secondary' onClick={handleLeaveWithoutSaving}>
                  Leave without saving
                </Button>
              </AlertDialogActionsWrapper>
            </AlertDialogContent>
          </AlertDialogOverlay>
        )}
      </ContentWrapper>
      {/* Gotcha, if the copy for this needs to be updated and shown to users who may have already seen it, the storageKey will need to be updated */}
      <SingleUseDialog
        storageKey='firstTimeEmergencyDialog'
        title={
          <>
            <AlertIcon aria-hidden /> Important
          </>
        }
      >
        This website does not provide bushfire warnings and alerts. Please visit{' '}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href='https://www.emergency.wa.gov.au/' target='_blank'>
          emergency.wa.gov.au
        </a>{' '}
        for official warnings and current information for bushfires, storms,
        cyclones and floods.
      </SingleUseDialog>
      {/* Upgrade message dialog */}
      <UpgradeMessageDialog />
    </>
  );
};

export default Layout;
