import { ReactComponent as IconFacebook } from 'assets/icons/icon-facebook.svg';
import { ReactComponent as IconInstagram } from 'assets/icons/icon-instagram.svg';
import { ReactComponent as IconLinkedIn } from 'assets/icons/icon-linkedin.svg';
import { ReactComponent as IconTwitter } from 'assets/icons/icon-twitter.svg';
import { ReactComponent as IconYouTube } from 'assets/icons/icon-youtube.svg';
import { HideVisually } from 'components/hide-visually';
import React, { FC } from 'react';
import { settings } from 'utils/config';
import { Intro, Item, Link, List, Wrapper } from './social-nav.styled';

const SocialNav: FC = () => {
  const iconProps = {
    width: 20,
    height: 20,
    'aria-hidden': true,
    role: 'presentation',
  };

  return (
    <Wrapper>
      <Intro>
        <strong>Follow us</strong>
      </Intro>
      <List>
        <Item>
          <Link
            href={settings.url.facebook}
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconFacebook {...iconProps} />
            <HideVisually>Facebook (opens in new window)</HideVisually>
          </Link>
        </Item>
        <Item>
          <Link
            href={settings.url.twitter}
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconTwitter {...iconProps} />
            <HideVisually>Twitter (opens in new window)</HideVisually>
          </Link>
        </Item>
        <Item>
          <Link
            href={settings.url.instagram}
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconInstagram {...iconProps} />
            <HideVisually>Instagram (opens in new window)</HideVisually>
          </Link>
        </Item>
        <Item>
          <Link
            href={settings.url.linkedin}
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconLinkedIn {...iconProps} />
            <HideVisually>LinkedIn (opens in new window)</HideVisually>
          </Link>
        </Item>
        <Item>
          <Link
            href={settings.url.youtube}
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconYouTube {...iconProps} />
            <HideVisually>YouTube (opens in new window)</HideVisually>
          </Link>
        </Item>
      </List>
    </Wrapper>
  );
};

export default SocialNav;
