import {
  Dialog as ReachDialog,
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from '@reach/dialog';
import { ReactComponent as CrossIcon } from 'assets/icons/icon-cross.svg';
import { H5 } from 'components/headings';
import { HideVisually } from 'components/hide-visually';
import { math, rem, transparentize } from 'polished';
import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    transform: translate3d(0, ${rem(-14)}, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const BaseDialogContentStyles = css`
  z-index: ${({ theme }) => theme.zIndex[4]};
  padding: ${rem(40)};
  color: ${({ theme }) => theme.colors.palette.black};
  font-size: ${rem(18)};
  line-height: 1.3;
  background-color: ${({ theme }) => theme.colors.palette.white};
  border-radius: ${({ theme }) => math(`(${theme.borderRadius.medium}) / 2 `)};
  outline: none;
  animation: ${fadeIn} 200ms ease-out both;

  @media (prefers-reduced-motion) {
    animation-name: none;
  }

  * + p {
    margin: 1em 0 0;
  }

  ul {
    padding: 0 0 0 1em;

    li + li {
      margin-top: 0.5em;
    }
  }
`;

export const DialogOverlayStyles = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex[4]};
  display: flex;
  overflow: auto;
  background-color: ${({ theme }) =>
    transparentize(0.5, theme.colors.palette.black)};
`;

export const DialogOverlay = styled(ReachDialogOverlay)`
  ${DialogOverlayStyles}
`;

export const DialogContentStyles = css`
  position: relative;
  width: calc(100% - 2.5rem);
  max-width: ${rem(340)};
  margin: auto;
  ${BaseDialogContentStyles}
`;

export const DialogContent = styled(ReachDialogContent)`
  ${DialogContentStyles}
`;

export const DialogTitle = styled(H5).attrs({ as: 'h2', id: 'dialog-title' })`
  margin: 0 0 1em;
  color: ${({ theme }) => theme.colors.palette.black};
`;

const DialogCloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: ${rem(4)};
  right: ${rem(4)};
  width: ${rem(44)};
  height: ${rem(44)};
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.palette.black};
  background-color: ${({ theme }) => theme.colors.palette.white};
  border: 0;
  border-radius: 100%;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.palette.redDark};
  }
`;

interface DialogCloseProps extends React.HTMLAttributes<HTMLButtonElement> {
  iconDimensions?: number;
}

export const DialogClose: FC<DialogCloseProps> = ({
  children,
  iconDimensions,
  ...props
}) => (
  <DialogCloseButton {...props}>
    <CrossIcon
      aria-hidden
      role='presentation'
      width={iconDimensions ? iconDimensions : 16}
      height={iconDimensions ? iconDimensions : 16}
    />
    <HideVisually>{children}</HideVisually>
  </DialogCloseButton>
);

const Dialog = styled(ReachDialog)`
  ${BaseDialogContentStyles}
`;

export default Dialog;
