import { PlanData, PlanDataTypeEnum, PlanDataWithMetadata } from 'api/client';
import { getPlanTitle } from 'utils/helpers';

export function getPageTitle(
  type: PlanDataTypeEnum,
  complete: boolean
): string {
  let planName: string = '';
  if (type === PlanDataTypeEnum.Leave) planName = 'Leave Early';
  if (type === PlanDataTypeEnum.Stay) planName = 'Stay and Defend';

  if (complete) {
    return `My ${planName} plan`;
  } else {
    return planName;
  }
}

export function derivePlanTitle(
  thisPlan: PlanData,
  allPlans: PlanDataWithMetadata[]
): string {
  const numberOfPlansOfType = allPlans.filter(
    ({ plan }) => plan.type === thisPlan.type
  ).length;

  const baseTitle = getPlanTitle(thisPlan.type);

  if (numberOfPlansOfType === 0) {
    return baseTitle;
  }

  return `${baseTitle} ${numberOfPlansOfType + 1}`;
}

export function getIntro(type: PlanDataTypeEnum): string {
  return type === PlanDataTypeEnum.Leave
    ? 'Because you have answered no or unsure your safest bushfire plan is to:'
    : 'Because you answered yes to all questions, it seems you are committed to the high risk plan:';
}

// H/T Noel @ Adapptor
export function getPlanPDFName(planType: PlanDataTypeEnum | undefined) {
  switch (planType) {
    case PlanDataTypeEnum.Stay:
      return 'DFES-StayAndDefendPlan';
    case PlanDataTypeEnum.Leave:
      return 'DFES-LeaveEarlyPlan';
    default:
      return 'DFES-Plan';
  }
}

export function getLetterFromIndex(index: number) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
  let result = '';

  if (index >= alphabet.length) {
    // IF index is higher then the number of letters in alphabet
    // Then form some grouping e.g. 1A, 1B, 2A etc
    const groupCount = Math.floor(index / alphabet.length);
    const remainder = index - groupCount * alphabet.length;
    result = `${groupCount}${alphabet[remainder].toUpperCase()}`;
  } else {
    result = alphabet[index].toUpperCase();
  }
  return result;
}
