import PrimaryNav from 'components/primary-nav';
import StatusBar from 'components/status-bar';
import useAuth from 'hooks/use-auth';
import { useIsLoggedIn } from 'hooks/use-is-loggedIn';
import { useSiteSettings } from 'hooks/use-siteSettings';
import { FC, useEffect, useState } from 'react';
import { SiteSettings } from 'types/sanity';
import { StyledHeader } from './header.styles';

interface Props {
  nav: SiteSettings['nav']['header'];
}

const Header: FC<Props> = (props) => {
  const { isVerified, loading, logout } = useAuth();
  const isLoggedIn = useIsLoggedIn();
  const { siteSettings } = useSiteSettings();
  const [isUnverified, setIsUnverified] = useState(false);

  useEffect(() => {
    setIsUnverified(!loading && isLoggedIn && !isVerified);
  }, [isLoggedIn, isVerified, loading]);

  return (
    <StyledHeader role='banner'>
      {isUnverified && (
        <StatusBar>
          Your email is not verified. Please check your inbox and follow
          instructions.
        </StatusBar>
      )}
      <PrimaryNav
        logoTitle={siteSettings.title}
        navItems={props.nav}
        isLoggedIn={isLoggedIn}
        onLogout={logout}
      />
    </StyledHeader>
  );
};

Header.defaultProps = {
  nav: [],
};

export default Header;
