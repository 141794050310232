import { hideVisually, rem } from 'polished';
import styled from 'styled-components';
import { Reveal } from '../hide-visually';

export const Wrapper = styled.div`
  display: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: ${rem(16)};

  ${({ theme }) => theme.mediaQueries.large} {
    display: flex;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Item = styled.li`
  display: inline-block;

  & + * {
    margin-left: ${rem(22)};
  }
`;

export const Intro = styled.p`
  ${hideVisually()}

  ${({ theme }) => theme.mediaQueries.small} {
    ${Reveal()}

    margin-right: ${rem(22)};
  }
`;

export const Link = styled.a`
  position: relative;
  display: inline-block;
  padding: 6px;
  color: ${({ theme }) => theme.colors.palette.white};
  text-decoration: none;
  transition: color 150ms ease-in-out;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${({ theme }) => theme.zIndex[0]};
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: box-shadow 150ms ease-in-out;
    content: '';
  }

  &:hover {
    color: ${({ theme }) => theme.colors.palette.redDark};
  }

  svg {
    position: relative;
    z-index: ${({ theme }) => theme.zIndex[1]};
    vertical-align: top;
  }
`;
