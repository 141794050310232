import Button from 'components/button';
import { rem } from 'polished';
import styled from 'styled-components';

export const Preview = styled.div`
  margin-top: var(--header-height);
  padding: ${({ theme }) => theme.space.small};
  color: ${({ theme }) => theme.colors.palette.black};
  font-weight: bold;
  text-align: center;
  background: ${({ theme }) => theme.colors.palette.orange};
`;

export const ExitPreview = styled(Button).attrs({
  type: 'button',
  variant: 'dark',
})`
  margin-left: ${rem(20)};
`;
