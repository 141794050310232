import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { settings } from 'utils/config';
import APP_ROUTES from 'utils/routes';
import { ExitPreview, Preview } from './preview-banner.styled';

interface Props {
  preview?: boolean;
}

const PreviewBanner: FC<Props> = ({ preview }) => {
  const router = useRouter();

  if (!preview) return null;

  const exitPreview = () => {
    Cookies.remove(settings.previewCookieName);
    router.replace(APP_ROUTES.home);
  };

  return (
    <Preview>
      This page is a preview.{' '}
      <ExitPreview onClick={exitPreview}>Exit preview mode</ExitPreview>
    </Preview>
  );
};

PreviewBanner.defaultProps = {
  preview: false,
};

export default PreviewBanner;
