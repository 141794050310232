import { Menu, MenuItem, MenuLink } from '@reach/menu-button';
import { ReactComponent as IconCross } from 'assets/icons/icon-cross.svg';
import { ReactComponent as IconEmergency } from 'assets/icons/icon-emergency.svg';
import { ReactComponent as IconHamburger } from 'assets/icons/icon-hamburger.svg';
import { HideVisually } from 'components/hide-visually';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import slugify from 'slugify';
import { LeaveEarlyIntroModeEnum } from 'types/base';
import { SiteSettings } from 'types/sanity';
import APP_ROUTES from 'utils/routes';
import {
  AlertButton,
  CloseButton,
  Logo,
  LogoContainer,
  MenuButton,
  MenuList,
  NavContainer,
} from './primary-nav.styles';

interface Props {
  logoTitle: string;
  onLogout: () => void;
  navItems?: SiteSettings['nav']['header'];
  isLoggedIn?: boolean;
}

const PrimaryNav: FC<Props> = ({
  navItems,
  isLoggedIn,
  logoTitle,
  onLogout,
}) => {
  const CMSLinks = useMemo(
    () =>
      navItems?.map((link, i) => {
        const target = link.blank ? '_blank' : undefined;
        const rel = link.blank ? 'noopener noreferrer' : undefined;
        const key = slugify(`${link.title}${i}`);

        return (
          <Link key={key} href='/[slug]' as={link.url} passHref>
            <MenuLink target={target} rel={rel}>
              {link.title}
            </MenuLink>
          </Link>
        );
      }),
    [navItems]
  );

  return (
    <Menu>
      <NavContainer>
        <Link href={APP_ROUTES.home}>
          <LogoContainer>
            <Logo
              src='/logo-dfes.svg'
              alt={`${logoTitle} Homepage`}
              width='158'
              height='60'
            />
          </LogoContainer>
        </Link>
        <MenuButton aria-label='Open Menu'>
          <IconHamburger aria-hidden />
        </MenuButton>
        <nav role='navigation'>
          <HideVisually as='h2'>Main navigation</HideVisually>
          <MenuList portal={false}>
            <CloseButton aria-label='Close Menu'>
              <IconCross aria-hidden />
            </CloseButton>
            <Link href={APP_ROUTES.home} passHref>
              <MenuLink>Home</MenuLink>
            </Link>
            {!isLoggedIn && (
              <>
                <Link
                  href={`${APP_ROUTES.leave.index}?mode=${LeaveEarlyIntroModeEnum.Default}`}
                  as={APP_ROUTES.leave.index}
                  passHref
                >
                  <MenuLink>Be safe and leave early plan</MenuLink>
                </Link>
                <Link href={APP_ROUTES.stay.index} passHref>
                  <MenuLink>Stay and defend plan</MenuLink>
                </Link>
              </>
            )}
            {isLoggedIn && (
              <>
                <Link href={APP_ROUTES.account} passHref>
                  <MenuLink>My bushfire plans</MenuLink>
                </Link>
                <Link href={APP_ROUTES.users.settings} passHref>
                  <MenuLink>Account settings</MenuLink>
                </Link>
              </>
            )}
            <Link href={APP_ROUTES.stayInformed} passHref>
              <MenuLink>Stay informed</MenuLink>
            </Link>
            <Link href={APP_ROUTES.resources} passHref>
              <MenuLink>Bushfire preparation resources</MenuLink>
            </Link>
            <Link href={APP_ROUTES.australianWarningSystem} passHref>
              <MenuLink>Australian Warning System</MenuLink>
            </Link>
            <Link href={APP_ROUTES.fireDangerRatings} passHref>
              <MenuLink>Fire Danger Rating System</MenuLink>
            </Link>
            {isLoggedIn && (
              <>
                {CMSLinks}
                <MenuItem onSelect={onLogout}>Logout</MenuItem>
              </>
            )}
            {!isLoggedIn && (
              <>
                {CMSLinks}
                <Link href={APP_ROUTES.users.signIn} passHref>
                  <MenuLink>Login</MenuLink>
                </Link>
                <Link href={APP_ROUTES.users.signUp} passHref>
                  <MenuLink>Create an account</MenuLink>
                </Link>
              </>
            )}
            <AlertButton
              href='https://www.emergency.wa.gov.au/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconEmergency />
              <div>
                <strong>Alerts and Warnings</strong>
                EmergencyWA
              </div>
              <HideVisually>(opens in new window)</HideVisually>
            </AlertButton>
          </MenuList>
        </nav>
      </NavContainer>
    </Menu>
  );
};

export default PrimaryNav;
