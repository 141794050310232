import { rem } from 'polished';
import styled from 'styled-components';

export const Copyright = styled.p`
  margin: 0;
  margin-top: ${rem(20)};
  font-size: ${rem(12)};

  ${({ theme }) => theme.mediaQueries.medium} {
    margin: 0;
    margin-left: ${rem(60)};
  }
`;

export const FooterGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.palette.white};
  background: ${({ theme }) => theme.colors.palette.black};

  ${({ theme }) => theme.mediaQueries.medium} {
    justify-content: center;
  }
`;

export const FooterLinks = styled.div`
  padding: ${rem(30)};
  color: ${({ theme }) => theme.colors.palette.white};
  background-color: ${({ theme }) => theme.colors.palette.greyDark};

  ${({ theme }) => theme.mediaQueries.medium} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    margin: 0;
    font-size: ${rem(12)};
    list-style: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    & > * + * {
      margin-top: ${rem(10)};

      ${({ theme }) => theme.mediaQueries.medium} {
        margin-top: 0;
      }
    }

    ${({ theme }) => theme.mediaQueries.medium} {
      display: inline-grid;
      grid-auto-flow: column;
      grid-gap: ${rem(30)};
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 150ms ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.palette.redDark};
      text-decoration: underline;
    }
  }
`;
