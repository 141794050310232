import produce from 'immer';
import { Contact, Place, PlanData } from '../client';

/**
 * The migration tool does not discern between Leave and Stay plan types. The reason for this, is
 * that Stay plans may actually become Leave plans if the user chooses to. Preferably, we won't lose
 * any data throughout the migration.
 */
export function migrate(plan: PlanData) {
  const updatedPlan = produce(plan, (draft) => {
    draft.version = 2;
    if (plan.whenToLeave) {
      draft.whenToLeaveWithSelections = {
        selections: [],
        notes: plan.whenToLeave,
      };
    }

    const place = getPlaceToGo(plan);
    if (place) {
      draft.whereToGoOptions = { places: [place] };
    }

    if (plan.whoToCall?.first || plan.whoToCall?.second) {
      const firstContact: Contact[] = plan.whoToCall.first
        ? [plan.whoToCall.first]
        : [];
      const secondContact: Contact[] = plan.whoToCall.second
        ? [plan.whoToCall.second]
        : [];
      draft.whoToCallOptions = {
        contacts: [...firstContact, ...secondContact],
      };
    }

    if (plan.whatToTake?.items) {
      draft.whatToTakeAndWhereToStore = {
        items: plan.whatToTake.items,
        kitLocation: '',
        acknowledged: false,
      };
    }

    if (plan.backup || plan.shelter) {
      draft.backupAndShelter = {
        nooneHome: { notes: plan.backup, selections: [] },
        guests: { selections: [] },
        shelter: { notes: plan.shelter, selections: [] },
      };
    }

    // Incomplete these as the migration requires the user to update the new fields and recomplete the plan
    draft.completed = false;
    draft.completionAcknowledged = false;
  });
  return updatedPlan;
}

function getPlaceToGo(plan: PlanData): Place | undefined {
  if (plan.whereToGo) {
    const nameOfPlaceToGo: string | undefined = plan.whereToGo.text[0];
    const wayToGetThere: string | undefined = plan.whereToGo.text[1];
    if (nameOfPlaceToGo || wayToGetThere) {
      const place: Place = {
        name: nameOfPlaceToGo || undefined,
        routes: wayToGetThere ? [wayToGetThere] : undefined,
      };

      return place;
    }
  }

  return undefined;
}
