import React, { FC } from 'react';
import { VisuallyHidden } from './hide-visually.styled';

interface Props extends React.HTMLAttributes<any> {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

/**
 * Component that "visually hides" the content from the UI for a11y purposes.
 * See: https://css-tricks.com/inclusively-hidden/
 */
export const HideVisually: FC<Props> = ({ children, as, ...props }) => (
  <VisuallyHidden as={as} {...props}>
    {children}
  </VisuallyHidden>
);
