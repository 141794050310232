import { rem, size } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem(16)} 0;

  ${({ theme }) => theme.mediaQueries.medium} {
    margin-right: ${rem(8)};
    margin-left: ${rem(8)};
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  ${({ theme }) => theme.mediaQueries.medium} {
    flex-direction: row;
    width: auto;
  }
`;

export const Item = styled.li`
  align-self: stretch;
`;

interface LinkProps {
  iconSize?: number;
}

export const Link = styled.a<LinkProps>`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${rem(18)} ${rem(28)};
  color: ${({ theme }) => theme.colors.palette.white};
  font-size: ${rem(16)};
  line-height: 1.3;
  text-align: left;
  text-decoration: none;
  transition: background-color 150ms ease-in-out;

  ${({ theme }) => theme.mediaQueries.medium} {
    padding: ${rem(10)} ${rem(30)} ${rem(7)};
    font-size: ${rem(14)};
  }

  strong {
    white-space: nowrap;

    ${({ theme }) => theme.mediaQueries.medium} {
      display: block;
    }
  }

  abbr {
    text-decoration: none;
  }

  svg {
    ${({ iconSize }) => {
      const baseSize = 18;
      const svgSize = iconSize ?? baseSize;

      return css`
        ${size(svgSize)};

        flex-shrink: 0;
        margin-right: ${rem(20 - (iconSize ? svgSize - baseSize : 0))};

        path {
          fill: #fff;
        }
      `;
    }}
  }
`;
