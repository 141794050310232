import { BackLinkStyled } from 'components/back-link/back-link.styles';
import Button from 'components/button';
import { headerBase } from 'components/header/header.styles';
import { rem, size } from 'polished';
import styled from 'styled-components';

export const Header = styled.div`
  ${headerBase};

  position: absolute;
  z-index: ${({ theme }) => theme.zIndex[1]};
  display: flex;
  align-items: center;
  pointer-events: none;

  a {
    pointer-events: all;
  }

  ${BackLinkStyled} {
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  ${size(24)}

  position: relative;
  margin-left: auto;
  background: none;
  border: 0;
  cursor: pointer;
  pointer-events: all;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    ${size('100%')}

    color: ${({ theme }) => theme.colors.palette.black};
  }
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.palette.black};
`;

export const SaveButton = styled(Button)`
  display: inline-block;
  margin: ${rem(30)} 0;
  background: none;
  border: 0;
`;
