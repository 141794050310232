import { hideVisually, rem } from 'polished';
import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    /**
      Disable the reach warning that styles are not imported.
      Reference: https://reach.tech/styling/
     */
    --reach-combobox: 1;
    --reach-dialog: 1;
    --reach-menu-button: 1;
    --reach-alert-dialog: 1;
    --header-height: ${rem(80)};
    --focus-color: ${({ theme }) => theme.colors.palette.redDark};

    ${({ theme }) => theme.mediaQueries.medium} {
      --header-height: ${rem(140)};
    }
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  html,
  body {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.text};
    font-weight: normal;
    font-family: 'Roboto Condensed', sans-serif;
    background-color: ${({ theme }) => theme.colors.background};
  }

  body {
    margin-top: var(--body-offset);
  }

  main {
    background-color: #222;
  }

  label {
    font-weight: 700;
  }

  a {
    color: currentColor;

    &:hover {
      text-decoration: none;
    }
  }

  :focus-visible  {
    outline: 0;
    box-shadow: inset 0 0 0 2px ${({ theme }) =>
      theme.colors.palette
        .white}, 0 0 0 2px var(--focus-color), 0 0 8px var(--focus-color);
  }
`;

export const SkipNav = styled.a.attrs({
  id: 'skip-nav',
  href: '#maincontent',
})`
  color: ${({ theme }) => theme.colors.palette.redDark};
  background-color: ${({ theme }) => theme.colors.background};
  ${{ ...hideVisually() }}

  &:focus {
    z-index: ${({ theme }) => theme.zIndex[5]};
    width: auto;
    height: auto;
    padding: 1rem;
    clip: auto;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

interface MainProps {
  background?: string | string[];
  dimBackground?: boolean;
}

export const Main = styled.main<MainProps>`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex[0]};
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    z-index: -1;
    display: ${({ background }) => (background ? 'block' : 'none')};
    width: 100%;
    content: '';
  }

  /* Background image */
  &::before {
    top: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.palette.greyDark};

    /* Handle background images, return if a string, loop and use media queries based on index if array,
    basically a ghetto version of what styled-system does :D */
    ${({ theme, background }) => {
      if (typeof background === 'string') {
        return css`
          background-image: url(${background});
        `;
      } else {
        return background?.map((item, index) => {
          if (index === 0) {
            return css`
              background-image: url(${item});
            `;
          }

          return css`
            ${Object.values(theme.mediaQueries)[index - 1]} {
              background-image: url(${item});
            }
          `;
        });
      }
    }}

    background-attachment: fixed;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: ${({ dimBackground }) => (dimBackground ? 0.5 : 1)};
  }
`;
