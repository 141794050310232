import { useEffect, useState } from 'react';
import useAuth from './use-auth';

export function useIsLoggedIn() {
  const { isAuthenticated, isAnonymous, loading } = useAuth();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(loading);

  useEffect(() => {
    setIsLoggedIn(!loading && isAuthenticated && !isAnonymous);
  }, [isAuthenticated, loading, isAnonymous]);

  return isLoggedIn;
}
