import { rem, rgba, size } from 'polished';
import styled, { css, keyframes, Theme } from 'styled-components';

export interface SpinnerProps {
  size?: number;
  color?: keyof Theme['colors']['palette'];
  space?: number;
  centre?: boolean;
}

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div.attrs({
  'data-testid': 'spinner',
})<SpinnerProps>`
  ${({
    size: spinnerSize = 32,
    color = 'white',
    theme,
    space = 0,
    centre = true,
  }) => {
    const mainColor = theme['colors']['palette'][color];

    return css`
      ${size(rem(spinnerSize))}

      display: block;
      margin: ${rem(space)} ${centre ? 'auto' : 0};
      border: ${rem(spinnerSize / 8)} solid ${rgba(mainColor, 0.3)};
      border-top-color: ${mainColor};
      border-radius: 50%;
      animation: 1.5s ${spin} infinite linear;
    `;
  }}
`;

export default Spinner;
