import { ReactComponent as IconEmergency } from 'assets/icons/icon-emergency.svg';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';
import React, { FC } from 'react';
import { Item, Link, List, Wrapper } from './emergency-nav.styled';

const EmergencyNav: FC = () => {
  const iconProps = { height: 16, width: 16 };

  return (
    <Wrapper>
      <List>
        <Item>
          <Link
            href='https://www.emergency.wa.gov.au/'
            target='_blank'
            rel='noopener noreferrer'
            title='Emergency WA (opens in new window)'
            iconSize={26}
          >
            <IconEmergency aria-hidden role='presentation' {...iconProps} />
            <div>
              <strong>Alerts &amp; Warnings</strong> EmergencyWA
            </div>
          </Link>
        </Item>
        <Item>
          <Link href='tel:000'>
            <IconPhone aria-label='Call' {...iconProps} />
            <div>
              <strong>To report a fire</strong> Contact Triple Zero (000)
              immediately
            </div>
          </Link>
        </Item>
        <Item>
          <Link href='tel:133337'>
            <IconPhone aria-label='Call' {...iconProps} />
            <div>
              <strong>DFES Emergency Information Line</strong> 13 DFES (13 3337)
            </div>
          </Link>
        </Item>
      </List>
    </Wrapper>
  );
};

export default EmergencyNav;
