import { logger } from '@hatchd/utils';
import {
  EmailRequest,
  ErrorResponse,
  FetchResult,
  PlanData,
  PlanDataWithMetadata,
  PlansApi,
} from 'api/client';
import { migratePlan } from 'api/migration/PlanMigrator';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { logPlanMigration } from 'services/analytics';
import { useStore } from 'store';
import { settings } from 'utils/config';
import { isDevBuild } from 'utils/helpers';
import statusMessages from 'utils/status-messages';
import { PLAN_VERSION } from '../api/migration/constants';
import useAuth from './use-auth';

export const queryKeys = {
  usePlans: 'planData',
};

export function usePlanAPI() {
  const { token, isRegisteredUser } = useAuth();
  const [set, getPlanId, getPlanDraftDirtyFlag] = useStore((state) => [
    state.set,
    state.getPlanId,
    state.getPlanDraftDirtyFlag,
  ]);
  const [ready, setReady] = useState(false);
  const plansApi = useRef<PlansApi>();
  const queryClient = useQueryClient();

  // Set up the plans api when the token is available
  useEffect(() => {
    if (token) {
      plansApi.current = new PlansApi({ apiKey: token });
      setReady(true);
    }
  }, [token]);

  // Note the "!" operator is used here as all methods are only ever called once the token is available via the "enabled" option
  const planHelpers = {
    // Get the users plans
    getPlans: async () => {
      return plansApi.current?.getPlans(PLAN_VERSION)!;
    },
    // Check if the email has any plans
    checkPlans: ({ email }: { email: string }) => {
      return plansApi.current?.checkPlans(email)!;
    },
    // Create a new plan
    createPlan: ({ plan }: { plan: PlanData }) => {
      return plansApi.current?.createPlan(plan, PLAN_VERSION)!;
    },
    // Update an existing plan
    upsertPlan: ({ id, plan }: { id: string; plan: PlanData }) => {
      return plansApi.current?.upsertPlan(id, plan, PLAN_VERSION)!;
    },
  };

  /**
   * Get the list of plans for the signed in user
   */
  const usePlans = () =>
    useQuery<FetchResult<PlanDataWithMetadata[]>>(
      queryKeys.usePlans,
      planHelpers.getPlans,
      {
        enabled: isRegisteredUser && ready,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          const currentPlanId = getPlanId();
          const isPlanDirty = getPlanDraftDirtyFlag();

          // Populate the remote draft plan data locally if no data currently exists
          // `data` can be undefined despite what the types say.
          if (
            data?.data.length &&
            isRegisteredUser &&
            !currentPlanId &&
            !isPlanDirty
          ) {
            // Grab the selected plan ID from localStorage
            const selectedPlanId = localStorage.getItem(
              settings.planIdStorageKey
            );

            // From the fetched plans get the data for the selected plan OR the first one in the list if localStorage value is undefined
            const planData =
              data?.data.find((plan) => plan.id === selectedPlanId) ||
              data.data[0];

            // Migrate the plan data received to ensure it's in the correct format for the current "PLAN_VERSION"
            const migratedPlan = migratePlan(planData.plan);

            // Log analytics when a plan has migrated successfully
            if (
              migratedPlan.type === 'migrated' ||
              migratedPlan.type === 'migration-failed'
            ) {
              logPlanMigration(migratedPlan, planData.plan.version);
            }

            const draft =
              migratedPlan.type === 'migrated'
                ? migratedPlan.plan
                : planData.plan;

            set(({ plan }) => {
              plan.planId = planData.id;
              plan.plans = [planData];
              plan.draft = draft;
              plan.updated = planData.updated;
            }, 'saveRemotePlanToDraft');

            // Persist selected plan ID to localStorage
            localStorage.setItem(settings.planIdStorageKey, planData.id);
          }
        },
        onError: (error: any) => {
          if (error?.hasOwnProperty('silent')) return;
          logger.error('usePlans error', error);
          toast.error(statusMessages.plan.fetchError);
        },
      }
    );

  /**
   * Check if the signed in user has at least one plan
   */
  const useHasExistingPlan = () => {
    const currentPlanId = getPlanId();
    return !!currentPlanId;
  };

  /**
   * Check if the email address passed in has any plans
   */
  const useCheckPlanForEmail = () => useMutation(planHelpers.checkPlans);

  /**
   * Create a new plan
   */
  const useCreatePlan = () =>
    useMutation(planHelpers.createPlan, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.usePlans);

        // Reset the dirty flag after updating the remote plan
        set(({ plan }) => {
          plan.isDirty = false;
          plan.updated = '';
        }, 'resetPlanDraftDirtyFlag');
      },
      onError: () => {
        toast.error(statusMessages.plan.createError);
      },
    });

  /**
   * Update an existing plan
   */
  const useUpsertPlan = () =>
    useMutation(planHelpers.upsertPlan, {
      onSuccess: () => {
        if (isRegisteredUser) {
          toast.success(statusMessages.plan.saveSuccess);
          queryClient.prefetchQuery(queryKeys.usePlans, planHelpers.getPlans);

          // Reset the dirty flag after updating the remote plan
          set(({ plan }) => {
            plan.isDirty = false;
          }, 'resetPlanDraftDirtyFlag');
        }
      },
      onError: () => {
        if (isRegisteredUser) {
          toast.error(statusMessages.plan.saveError);
        } else {
          toast.error(statusMessages.plan.saveErrorUnauthenticated);
        }
      },
    });

  return {
    usePlans,
    useHasExistingPlan,
    useCheckPlanForEmail,
    useCreatePlan,
    useUpsertPlan,
  };
}

// TODO: Move to above hook?
export async function emailPlan(token: string, data: EmailRequest) {
  try {
    await new PlansApi({ apiKey: token }).emailPlan(data);
    return Promise.resolve({
      message: 'Email has been sent.',
      success: true,
    });
  } catch (error) {
    // `emailPlan` returns a Response promise when it throws...
    const response: ErrorResponse = await error.json();
    let message =
      'An error has occurred while sending the email. Please try again later.';

    if (isDevBuild()) {
      message += ` Server error: ${response.message}`;
    }

    return Promise.reject({
      message,
      success: false,
    });
  }
}
