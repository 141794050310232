import { hideVisually } from 'polished';
import styled, { css } from 'styled-components';

export const VisuallyHidden = styled.span`
  ${hideVisually()};
`;

export const Reveal = () => css`
  position: unset;
  width: unset;
  height: unset;
  margin: unset;
  padding: unset;
  overflow: unset;
  white-space: unset;
  clip: unset;
`;

export const Visible = styled.div`
  ${Reveal()}
`;
