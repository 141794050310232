import Portal from '@reach/portal';
import { ReactComponent as AlertIcon } from 'assets/icons/icon-alert.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/icon-cross.svg';
import { FC, ReactNode } from 'react';
import { Bar, BarGlobals, StatusBarClose } from './status-bar.styles';

interface StatusBarProps {
  children: ReactNode;
  onClose?: () => void;
}

const StatusBar: FC<StatusBarProps> = ({ children, onClose }) => {
  return (
    <>
      <BarGlobals />
      <Portal>
        <Bar>
          <AlertIcon />
          {children}
          {onClose && (
            <StatusBarClose onClick={onClose}>
              <CrossIcon />
            </StatusBarClose>
          )}
        </Bar>
      </Portal>
    </>
  );
};

export default StatusBar;
