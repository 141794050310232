import { logger } from '@hatchd/utils';
import { UpdateUserProfileRequest, UsersApi } from 'api/client';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import statusMessages from 'utils/status-messages';
import useAuth from './use-auth';

export const queryKey = 'userProfile';

export function useUserProfileAPI() {
  const { token, isRegisteredUser } = useAuth();
  const queryClient = useQueryClient();
  const [ready, setReady] = useState(false);
  const usersApi = useRef<UsersApi>();

  // Set up the users api when the token is available
  useEffect(() => {
    if (token) {
      usersApi.current = new UsersApi({ apiKey: token });
      setReady(true);
    }
  }, [token]);

  const getUserProfile = async () => usersApi.current?.getUserProfile();
  const updateUserProfile = async (data: UpdateUserProfileRequest) =>
    usersApi.current?.updateUserProfile(data);

  const useUserProfile = () =>
    useQuery(queryKey, getUserProfile, {
      enabled: isRegisteredUser && ready,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        if (error?.hasOwnProperty('silent')) return;
        logger.error('useUserProfile', error);
        toast.error(statusMessages.auth.userProfileFetchError);
      },
    });

  const useUpdateUserProfile = (showToast: boolean = true) =>
    useMutation(updateUserProfile, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        if (showToast) {
          toast.success(statusMessages.auth.userProfileUpdateSuccess);
        }
      },
      onError: (error: any) => {
        if (error?.hasOwnProperty('silent')) return;
        logger.error('useUserProfile', error);
        toast.error(statusMessages.auth.userProfileUpdateError);
      },
    });

  return {
    useUserProfile,
    useUpdateUserProfile,
  };
}
