import { logger } from '@hatchd/utils';
import { PLAN_VERSION } from 'api/migration/constants';
import { DialogClose, DialogContent, DialogOverlay } from 'components/dialog';
import { usePlanAPI } from 'hooks/use-plan';
import { useUserProfileAPI } from 'hooks/use-user-profile';
import React, { FC, useEffect, useState } from 'react';

/**
 * A dialog which will only render when the user is seeing a new PLAN_VERSION for the first time
 */
const UpgradeMessageDialog: FC = () => {
  const { useUserProfile, useUpdateUserProfile } = useUserProfileAPI();
  const { usePlans } = usePlanAPI();
  const { mutate: updateUserProfile } = useUpdateUserProfile();
  const { data: plans } = usePlans();
  const { data: userProfile } = useUserProfile();

  // Dialog state
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const close = () => {
    setShowDialog(false);

    // Update the user object to the latest app version
    if (userProfile?.data) {
      logger.log(`Updating latest acknowledged version to "${PLAN_VERSION}"`);
      updateUserProfile({
        ...userProfile.data,
        latestAcknowledgedVersion: PLAN_VERSION,
      });
    }
  };

  // Open the dialog if the user has a plan and the last version they acknowledged does not match the current PLAN_VERSION
  useEffect(() => {
    if (
      plans !== undefined &&
      plans.data.length !== 0 &&
      userProfile !== undefined &&
      userProfile?.data.latestAcknowledgedVersion !== PLAN_VERSION
    ) {
      logger.info('Show upgrade message, user has not seen the latest version');
      setShowDialog(true);
    }
  }, [plans, userProfile]);

  return (
    <DialogOverlay isOpen={showDialog} onDismiss={close} aria-label=''>
      <DialogContent aria-label={`My Bushfire Plan Version ${PLAN_VERSION}`}>
        The My Bushfire Plan website and app have been updated to help you make
        your bushfire plan even more effective. There are new features, more
        resources, and more information throughout to help you make a fireproof
        plan. Create or review your plan now.
        <DialogClose onClick={close}>Close</DialogClose>
      </DialogContent>
    </DialogOverlay>
  );
};

export default UpgradeMessageDialog;
