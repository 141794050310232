import { rem } from 'polished';
import styled, { css } from 'styled-components';

const common = css`
  color: ${({ theme }) => theme.components.heading.text};
  font-weight: 700;
  text-shadow: 0 2px 6px rgba(255, 255, 255, 0.2);
`;

export const H1 = styled.h1`
  ${common}

  margin: 0 0 1rem;
  font-size: ${rem(80)};
  line-height: 1.15;
`;

export const H2 = styled.h2`
  ${common}

  margin: 0 0 1rem;
  font-size: ${rem(24)};
  line-height: 1.15;

  ${({ theme }) => theme.mediaQueries.small} {
    font-size: ${rem(36)};
  }

  ${({ theme }) => theme.mediaQueries.medium} {
    font-size: ${rem(48)};
  }
`;

export const H3 = styled.h3`
  ${common}

  margin: 0 0 1rem;
  font-size: ${rem(36)};
  line-height: 1.15;
`;

export const H4 = styled.h4`
  ${common}

  margin: 0 0 1rem;
  font-size: ${rem(24)};
  line-height: 1.15;
`;

export const H5 = styled.h5`
  ${common}

  margin: 0 0 1rem;
  font-size: ${rem(18)};
  line-height: 1.15;
`;

const Headings = {
  H1,
  H2,
  H3,
  H4,
};

export default Headings;
