import { rem, size } from 'polished';
import styled from 'styled-components';

export const BackLinkStyled = styled.a`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: ${rem(30)};
  margin-left: ${rem(14)};
  color: ${({ theme }) => theme.colors.palette.black};
  font-weight: bold;
  text-decoration: none;

  &::before {
    --translate: ${rem(-10)};

    ${size(12)};

    display: block;
    border: 2px solid currentColor;
    border-top: 0;
    border-right: 0;
    transform: translateX(var(--translate)) rotate(45deg);
    transition: 0.25s ease-in-out;
    content: '';
  }

  &:hover::before {
    --translate: ${rem(-14)};
  }
`;
