import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const headerBase = css`
  width: 100%;
  height: var(--header-height);
  padding: ${rem(20)} ${rem(30)};

  ${({ theme }) => theme.mediaQueries.medium} {
    padding: ${rem(40)} ${rem(50)};
  }
`;

export const StyledHeader = styled.header`
  ${headerBase};

  position: absolute;
  top: var(--body-offset, 0);
  left: 0;
  z-index: ${({ theme }) => theme.zIndex[4]};
  display: flex;
  flex-direction: column;
  justify-content: center;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    content: '';
  }
`;
