import { rem, size } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';

export const BarGlobals = createGlobalStyle`
  :root {
    --body-offset: ${rem(65)};

    ${({ theme }) => theme.mediaQueries.medium} {
      --body-offset: ${rem(45)};
    }
  }
`;

export const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--body-offset);
  padding: 0 ${rem(10)};
  color: ${({ theme }) => theme.colors.palette.black};
  background-color: ${({ theme }) => theme.colors.palette.orange};

  svg {
    display: block;
    margin-right: ${rem(10)};
    ${size(rem(24))}

    ${({ theme }) => theme.mediaQueries.small} {
      margin-right: ${rem(20)};
    }
  }
`;

export const StatusBarClose = styled.button.attrs({ type: 'button' })`
  width: ${rem(16)};
  height: ${rem(16)};
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.palette.black};
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mediaQueries.medium} {
    position: absolute;
    top: ${rem(16)};
    right: ${rem(56)};
  }
`;

export const StatusBarLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;
