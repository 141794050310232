import {
  MenuLink,
  MenuButton as ReachMenuButton,
  MenuList as ReachMenuList,
} from '@reach/menu-button';
import { lighten, rem, size } from 'polished';
import styled, { css } from 'styled-components';

export const NavContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AlertButton = styled(MenuLink)`
  display: flex;
  align-items: center;
  margin-top: var(--padding);
  margin-right: ${rem(15)};
  margin-left: ${rem(15)};
  padding: ${rem(20)};
  color: inherit;
  text-decoration: none;
  background-color: ${({ theme }) => lighten(0.45, theme.colors.palette.grey)};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  cursor: pointer;

  svg {
    flex-shrink: 0;
    width: ${rem(35)};
    height: ${rem(22)};
    margin-right: ${rem(20)};
  }

  strong {
    display: block;
  }
`;

export const MenuList = styled(ReachMenuList)`
  --padding: ${rem(15)};

  position: absolute;
  top: ${rem(-10)};
  right: ${rem(-20)};
  width: 100%;
  max-width: ${rem(340)};
  padding: ${rem(50)} var(--padding);
  color: ${({ theme }) => theme.colors.palette.black};
  background-color: ${({ theme }) => theme.colors.palette.white};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: 0 4px 80px rgba(0, 0, 0, 0.4);

  ${({ theme }) => theme.mediaQueries.medium} {
    top: ${rem(1)};
    right: ${rem(-16)};
  }

  [data-selected] {
    color: ${({ theme }) => theme.colors.palette.white};
    background-color: ${({ theme }) => theme.colors.palette.black};
  }

  @keyframes enter {
    0% {
      transform: translateY(${rem(-20)});
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  & {
    animation: enter 0.2s ease;

    @media (prefers-reduced-motion) {
      transform: none;
      opacity: 1;
      animation-name: none;
    }
  }

  &:focus {
    outline: 0;
  }

  a:not(${AlertButton}),
  [data-reach-menu-item]:not(${AlertButton}) {
    display: block;
    padding: var(--padding);
    text-decoration: none;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    cursor: pointer;
  }
`;

const navContainer = css`
  padding: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const buttonBase = css`
  --fill-color: ${({ theme }) => theme.colors.palette.black};

  ${size(20)}

  position: relative;
  display: block;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &::before {
    ${size('100%')}

    position: absolute;
    top: 0;
    left: 0;
    transform: scale(2);
    content: '';
  }

  svg {
    ${size('100%')}

    position: absolute;
    top: 0;
    left: 0;
    color: var(--fill-color);
  }
`;

export const MenuButton = styled(ReachMenuButton)`
  --fill-color: ${({ theme }) => theme.colors.palette.black};

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0;
  border: none;
  cursor: pointer;

  svg {
    ${size(24)}

    color: var(--fill-color);
  }

  ${({ theme }) => theme.mediaQueries.medium} {
    ${size(60)};

    background-color: ${({ theme }) => theme.colors.background};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
  }
`;

export const CloseButton = styled(ReachMenuButton)`
  ${buttonBase}
  --fill-color: ${({ theme }) => theme.colors.palette.black};

  position: absolute;
  top: ${rem(20)};
  right: ${rem(20)};
`;

export const LogoContainer = styled.a`
  cursor: pointer;
  ${({ theme }) => theme.mediaQueries.medium} {
    ${navContainer};
  }
`;

export const Logo = styled.img`
  display: block;
  width: ${rem(100)};
  height: ${rem(38)};

  ${({ theme }) => theme.mediaQueries.medium} {
    width: ${rem(158)};
    height: ${rem(60)};
  }
`;
