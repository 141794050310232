import {
  AlertDialog as ReachAlertDialog,
  AlertDialogContent as ReachAlertDialogContent,
  AlertDialogDescription as ReachAlertDialogDescription,
  AlertDialogLabel as ReachAlertDialogLabel,
  AlertDialogOverlay as ReachAlertDialogOverlay,
} from '@reach/alert-dialog';
import { StyledButton } from 'components/button';
import {
  BaseDialogContentStyles,
  DialogContentStyles,
  DialogOverlayStyles,
} from 'components/dialog';
import { H4 } from 'components/headings';
import { rem } from 'polished';
import styled from 'styled-components';

/**
 * NOTE:
 * Every dialog must render an `AlertDialogLabel` so the screen reader knows
 * what to say about the dialog. If an `AlertDialogDescription` is also rendered,
 * the screen reader will also announce that. If you render more than these two
 * elements and some buttons, the screen reader might not announce it so it's important
 * to keep the content inside of `AlertDialogLabel` and `AlertDialogDescription`.
 */

const AlertDialog = styled(ReachAlertDialog)`
  ${BaseDialogContentStyles}
`;

export const AlertDialogOverlay = styled(ReachAlertDialogOverlay)`
  ${DialogOverlayStyles}
`;

export const AlertDialogContent = styled(ReachAlertDialogContent)`
  ${DialogContentStyles}

  max-width: ${rem(320)};
  text-align: center;
`;

export const AlertDialogLabel = styled(H4).attrs({
  as: ReachAlertDialogLabel,
})`
  color: ${({ theme }) => theme.colors.palette.black};
`;

export const AlertDialogDescription = styled(ReachAlertDialogDescription)`
  margin-bottom: ${rem(30)};
`;

export const AlertDialogActionsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;

  ${StyledButton} {
    margin-top: ${rem(20)};
  }
`;

export default AlertDialog;
