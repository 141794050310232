import EmergencyNav from 'components/emergency-nav';
import { HideVisually } from 'components/hide-visually';
import SocialNav from 'components/social-nav';
import Link from 'next/link';
import React, { FC } from 'react';
import slugify from 'slugify';
import { SiteSettings } from 'types/sanity';
import { Copyright, FooterGroup, FooterLinks } from './footer.styled';

interface Props {
  nav: SiteSettings['nav']['footer'];
}

const Footer: FC<Props> = (props) => {
  return (
    <footer role='contentinfo'>
      <nav aria-labelledby='footer-nav'>
        <FooterGroup>
          <HideVisually as='h2' id='footer-nav'>
            Footer navigation
          </HideVisually>
          <EmergencyNav />
          <SocialNav />
        </FooterGroup>
        <FooterLinks>
          <ul>
            {!!props.nav.length &&
              props.nav.map((link, i) => {
                const target = link.blank ? '_blank' : undefined;
                const rel = link.blank ? 'noopener noreferrer' : undefined;
                const key = slugify(`${link.title}${i}`);
                return (
                  <li key={key}>
                    <Link href='/[slug]' as={link.url}>
                      <a target={target} rel={rel}>
                        {link.title}
                      </a>
                    </Link>
                  </li>
                );
              })}
            <li>
              <Link href='/sitemap'>Sitemap</Link>
            </li>
          </ul>
          <Copyright>
            &copy; Government of Western Australia. All rights reserved.
          </Copyright>
        </FooterLinks>
      </nav>
    </footer>
  );
};

Footer.defaultProps = {
  nav: [],
};

export default Footer;
